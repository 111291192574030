import React, { Component, Suspense } from 'react';
import { Helmet } from 'react-helmet-async';
import { Routes, Route, Navigate, Link, NavLink } from 'react-router-dom';
import makeCancellablePromise from 'make-cancellable-promise';
import { doc, onSnapshot, collection, getDoc, getDocs, query, where, limit } from 'firebase/firestore';
import { onAuthStateChanged, signOut } from 'firebase/auth';
import { toast } from 'react-toastify';
import { startOfMonth, endOfMonth, endOfDay } from 'date-fns';
import {
  RiUserLine, RiStoreLine, RiBuildingLine, RiDashboardLine, RiHotelLine, RiDashboard2Line, RiGroupLine, RiReservedLine, RiTv2Line, RiBookletLine, RiContactsBookUploadLine, RiBook2Line, RiExportLine, RiArchiveStackLine, RiDiscountPercentLine, RiBriefcase4Line, RiCashLine, RiShoppingBasket2Line,
  RiHistoryLine, RiStore3Line, RiBookReadLine, RiFileCopy2Line, RiCake3Line, RiEqualizerLine, RiSettings3Line, RiNodeTree, RiArrowDownSFill, RiBrush3Line,
  RiLoginCircleLine, RiUserSettingsLine, RiCashFill, RiUserStarLine, RiPriceTag2Line, RiBankCardLine, RiPriceTag3Line, RiListSettingsLine, RiShareLine, RiDraftLine, RiStackLine,
  // RiHomeGearLine, RiSecurePaymentLine, RiQuestionLine, RiFileList3Line, RiShieldUserLine, RiShieldKeyholeLine, RiSurveyLine, RiListCheck2, RiDashboard3Line,
  RiHomeGearLine, RiQuestionLine, RiFileList3Line, RiShieldUserLine, RiUserForbidLine, RiShieldKeyholeLine, RiSurveyLine, RiListCheck2, RiFridgeLine, RiDashboard3Line, RiBankLine,
  RiMoneyDollarCircleLine, RiExchangeLine, RiPieChartLine, RiPieChartBoxLine, RiGroup2Line, RiShoppingCartLine, RiWalletLine, RiTable2, RiStarSmileFill, RiStarSmileLine
} from 'react-icons/ri';

import Loading from './../components/Loading';
import { auth, db } from './../helper/firebase';
import { ReactComponent as Logo } from './../assets/img/logo.svg';
import SelectBox from './../components/SelectBox';

const Login = React.lazy(() => import('./Login/Login')),
// const Login = React.lazy(() => {
//         return new Promise(resolve => {
//           setTimeout(() => resolve(import('./Login/Login')), 3000000);
//         });
//       }),
      Billing = React.lazy(() => import('./Billing/Billing')),
      Dashboard = React.lazy(() => import('./Dashboard/Dashboard')),
      DashboardTransaction = React.lazy(() => import('./DashboardTransaction/DashboardTransaction')),
      DashboardFinance = React.lazy(() => import('./DashboardFinance/DashboardFinance')),
      DashboardItem = React.lazy(() => import('./DashboardItem/DashboardItem')),
      DashboardVariant = React.lazy(() => import('./DashboardVariant/DashboardVariant')),
      DashboardItemRate = React.lazy(() => import('./DashboardItemRate/DashboardItemRate')),
      DashboardVariantRate = React.lazy(() => import('./DashboardVariantRate/DashboardVariantRate')),
      DashboardUser = React.lazy(() => import('./DashboardUser/DashboardUser')),
      DashboardType = React.lazy(() => import('./DashboardType/DashboardType')),
      DashboardPayment = React.lazy(() => import('./DashboardPayment/DashboardPayment')),
      DashboardTable = React.lazy(() => import('./DashboardTable/DashboardTable')),
      OrderLive = React.lazy(() => import('./OrderLive/OrderLive')),
      OrderKitchen = React.lazy(() => import('./OrderKitchen/OrderKitchen')),
      OrderHistory = React.lazy(() => import('./OrderHistory/OrderHistory')),
      ReportTransaction = React.lazy(() => import('./ReportTransaction/ReportTransaction')),
      ReportItem = React.lazy(() => import('./ReportItem/ReportItem')),
      ReportSell = React.lazy(() => import('./ReportSell/ReportSell')),
      ReportIncome = React.lazy(() => import('./ReportIncome/ReportIncome')),
      ReportOthers = React.lazy(() => import('./ReportOthers/ReportOthers')),
      ReportDisbursement = React.lazy(() => import('./ReportDisbursement/ReportDisbursement')),
      ReportSettlement = React.lazy(() => import('./ReportSettlement/ReportSettlement')),
      OperationalAvailability = React.lazy(() => import('./OperationalAvailability/OperationalAvailability')),
      OperationalCustom = React.lazy(() => import('./OperationalCustom/OperationalCustom')),
      OperationalKitchen = React.lazy(() => import('./OperationalKitchen/OperationalKitchen')),
      OperationalKitchenForm = React.lazy(() => import('./OperationalKitchen/OperationalKitchenForm/OperationalKitchenForm')),
      OperationalQr = React.lazy(() => import('./OperationalQr/OperationalQr')),
      OperationalQrForm = React.lazy(() => import('./OperationalQr/OperationalQrForm/OperationalQrForm')),
      // OperationalPayment = React.lazy(() => import('./OperationalPayment/OperationalPayment')),
      // OperationalPaymentForm = React.lazy(() => import('./OperationalPayment/OperationalPaymentForm/OperationalPaymentForm')),
      OperationalLoyalty = React.lazy(() => import('./OperationalLoyalty/OperationalLoyalty')),
      OperationalLoyaltyForm = React.lazy(() => import('./OperationalLoyalty/OperationalLoyaltyForm/OperationalLoyaltyForm')),
      OperationalLoyaltyStamp = React.lazy(() => import('./OperationalLoyalty/OperationalLoyaltyStamp/OperationalLoyaltyStamp')),
      OrganizationClient = React.lazy(() => import('./OrganizationClient/OrganizationClient')),
      OrganizationClientForm = React.lazy(() => import('./OrganizationClient/OrganizationClientForm/OrganizationClientForm')),
      OrganizationBrand = React.lazy(() => import('./OrganizationBrand/OrganizationBrand')),
      OrganizationBrandForm = React.lazy(() => import('./OrganizationBrand/OrganizationBrandForm/OrganizationBrandForm')),
      OrganizationOutlet = React.lazy(() => import('./OrganizationOutlet/OrganizationOutlet')),
      OrganizationOutletForm = React.lazy(() => import('./OrganizationOutlet/OrganizationOutletForm/OrganizationOutletForm')),
      OrganizationGroup = React.lazy(() => import('./OrganizationGroup/OrganizationGroup')),
      OrganizationGroupForm = React.lazy(() => import('./OrganizationGroup/OrganizationGroupForm/OrganizationGroupForm')),
      OrganizationLabel = React.lazy(() => import('./OrganizationLabel/OrganizationLabel')),
      OrganizationLabelForm = React.lazy(() => import('./OrganizationLabel/OrganizationLabelForm/OrganizationLabelForm')),
      OrganizationBank = React.lazy(() => import('./OrganizationBank/OrganizationBank')),
      OrganizationBankForm = React.lazy(() => import('./OrganizationBank/OrganizationBankForm/OrganizationBankForm')),
      MenuEditor = React.lazy(() => import('./MenuEditor/MenuEditor')),
      MenuCategory = React.lazy(() => import('./MenuCategory/MenuCategory')),
      MenuCategoryForm = React.lazy(() => import('./MenuCategory/MenuCategoryForm/MenuCategoryForm')),
      MenuItem = React.lazy(() => import('./MenuItem/MenuItem')),
      MenuItemForm = React.lazy(() => import('./MenuItem/MenuItemForm/MenuItemForm')),
      MenuCustomizationGroup = React.lazy(() => import('./MenuCustomizationGroup/MenuCustomizationGroup')),
      MenuCustomizationGroupForm = React.lazy(() => import('./MenuCustomizationGroup/MenuCustomizationGroupForm/MenuCustomizationGroupForm')),
      MenuCustomizationItem = React.lazy(() => import('./MenuCustomizationItem/MenuCustomizationItem')),
      MenuCustomizationItemForm = React.lazy(() => import('./MenuCustomizationItem/MenuCustomizationItemForm/MenuCustomizationItemForm')),
      MenuTag = React.lazy(() => import('./MenuTag/MenuTag')),
      MenuTagForm = React.lazy(() => import('./MenuTag/MenuTagForm/MenuTagForm')),
      UserAdmin = React.lazy(() => import('./UserAdmin/UserAdmin')),
      UserAdminForm = React.lazy(() => import('./UserAdmin/UserAdminForm/UserAdminForm')),
      UserMember = React.lazy(() => import('./UserMember/UserMember')),
      UserMemberForm = React.lazy(() => import('./UserMember/UserMemberForm/UserMemberForm')),
      ConfigLink = React.lazy(() => import('./ConfigLink/ConfigLink')),
      ConfigFaq = React.lazy(() => import('./ConfigFaq/ConfigFaq')),
      ConfigFaqForm = React.lazy(() => import('./ConfigFaq/ConfigFaqForm/ConfigFaqForm')),
      ConfigTerm = React.lazy(() => import('./ConfigTerm/ConfigTerm')),
      ConfigPrivacy = React.lazy(() => import('./ConfigPrivacy/ConfigPrivacy')),
      ConfigDeletion = React.lazy(() => import('./ConfigDeletion/ConfigDeletion')),
      ConfigAccess = React.lazy(() => import('./ConfigAccess/ConfigAccess')),
      ConfigBank = React.lazy(() => import('./ConfigBank/ConfigBank')),
      ConfigBankForm = React.lazy(() => import('./ConfigBank/ConfigBankForm/ConfigBankForm')),
      ConfigSetting = React.lazy(() => import('./ConfigSetting/ConfigSetting'));

class App extends Component{
  constructor(){
    super();

    const newDate = new Date();
    
    this.state = {
      signIn: '',
      dataUser: {
        uid: '',
        name: '',
        email: '',
        phone: '',
        idPri: '',
        idClient: null,
        idBrand: null,
        idOutlet: null,
        role: ''
      },
      client: null,
      clientList: [],
      clientLoad: true,
      clientDisabled: false,
      brand: null,
      brandList: [],
      brandFilter: [],
      brandLoad: true,
      outlet: null,
      outletList: [],
      outletFilter: [],
      outletLoad: true,
      navPro: false,
      autoPlay: false,
      page: {
        name: '',
        icon: ''
      },
      navLoad: true,
      nav: [{
        name: 'Dasbor',
        icon: <RiDashboard2Line />,
        to: '',
        open: false,
        disabled: false,
        show: '',
        child: [{
          name: 'Utama',
          icon: <RiDashboard3Line />,
          to: '/dasbor-utama',
          disabled: false,
          show: ''
        }, {
          name: 'Transaksi',
          icon: <RiExchangeLine />,
          to: '/dasbor-transaksi',
          disabled: false,
          show: ''
        }, {
          name: 'Keuangan',
          icon: <RiMoneyDollarCircleLine />,
          to: '/dasbor-keuangan',
          disabled: false,
          show: ''
        }, {
          name: 'Penjualan Item',
          icon: <RiPieChartLine />,
          to: '/dasbor-penjualan-item',
          disabled: false,
          show: ''
        }, {
          name: 'Penjualan Varian',
          icon: <RiPieChartBoxLine />,
          to: '/dasbor-penjualan-varian',
          disabled: false,
          show: ''
        }, {
          name: 'Penilaian Item',
          icon: <RiStarSmileLine />,
          to: '/dasbor-penilaian-item',
          disabled: false,
          show: ''
        }, {
          name: 'Penilaian Varian',
          icon: <RiStarSmileFill />,
          to: '/dasbor-penilaian-varian',
          disabled: false,
          show: ''
        }, {
          name: 'Pelanggan',
          icon: <RiGroup2Line />,
          to: '/dasbor-pelanggan',
          disabled: false,
          show: ''
        }, {
          name: 'Tipe',
          icon: <RiShoppingCartLine />,
          to: '/dasbor-tipe',
          disabled: false,
          show: ''
        }, {
          name: 'Pembayaran',
          icon: <RiWalletLine />,
          to: '/dasbor-pembayaran',
          disabled: false,
          show: ''
        }, {
          name: 'Meja',
          icon: <RiTable2 />,
          to: '/dasbor-meja',
          disabled: false,
          show: ''
        }]
      }, {
        name: 'Orderan',
        icon: <RiShoppingBasket2Line />,
        to: '',
        open: false,
        disabled: false,
        show: '',
        child: [{
          name: 'Display Kasir',
          icon: <RiReservedLine />,
          to: '/orderan-kasir',
          disabled: false,
          show: ''
        }, {
          name: 'Display Dapur',
          icon: <RiTv2Line />,
          to: '/orderan-dapur',
          disabled: false,
          show: ''
        }, {
          name: 'Riwayat',
          icon: <RiHistoryLine />,
          to: '/orderan-riwayat',
          disabled: false,
          show: ''
        }]
      }, {
        name: 'Laporan',
        icon: <RiBookletLine />,
        to: '',
        open: false,
        disabled: false,
        show: '',
        child: [{
          name: 'Transaksi',
          icon: <RiContactsBookUploadLine />,
          to: '/laporan-transaksi',
          disabled: false,
          show: ''
        }, {
          name: 'Item',
          icon: <RiBook2Line />,
          to: '/laporan-item',
          disabled: false,
          show: ''
        }, {
          name: 'Penjualan',
          icon: <RiExportLine />,
          to: '/laporan-penjualan',
          disabled: false,
          show: ''
        }, {
          name: 'Pendapatan',
          icon: <RiArchiveStackLine />,
          to: '/laporan-pendapatan',
          disabled: false,
          show: ''
        }, {
          name: 'Biaya Lain-Lain',
          icon: <RiDiscountPercentLine />,
          to: '/laporan-biaya',
          disabled: false,
          show: ''
        }, {
          name: 'Settlement',
          icon: <RiBriefcase4Line />,
          to: '/laporan-settlement',
          disabled: false,
          show: ''
        }, {
          name: 'Pencairan',
          icon: <RiCashLine />,
          to: '/laporan-pencairan',
          disabled: false,
          show: ''
        }]
      }, {
        name: 'Operasional',
        icon: <RiHomeGearLine />,
        to: '',
        open: false,
        disabled: true,
        show: '',
        child: [{
          name: 'Item & Varian',
          icon: <RiStackLine />,
          to: '/operasional-item',
          disabled: false,
          show: ''
        }, {
          name: 'Variasi',
          icon: <RiListCheck2 />,
          to: '/operasional-variasi',
          disabled: false,
          show: ''
        }, {
          name: 'Dapur',
          icon: <RiFridgeLine />,
          to: '/operasional-dapur',
          disabled: false,
          show: ''
        }, {
          name: 'Kode QR',
          icon: <RiDashboardLine />,
          to: '/operasional-kode-qr',
          disabled: false,
          show: ''
        },
        // {
        //   name: 'Pembayaran',
        //   icon: <RiSecurePaymentLine />,
        //   to: '/operasional-pembayaran',
        //   disabled: false,
        //   show: ''
        // },
        {
          name: 'Loyalty',
          icon: <RiBrush3Line />,
          to: '/operasional-loyalty',
          disabled: false,
          show: ''
        }]
      }, {
        name: 'Menu',
        icon: <RiBookReadLine />,
        to: '',
        open: false,
        disabled: false,
        show: '',
        child: [{
          name: 'Editor',
          icon: <RiDraftLine />,
          to: '/menu-editor',
          disabled: false,
          show: ''
        }, {
          name: 'Kategori',
          icon: <RiFileCopy2Line />,
          to: '/menu-kategori',
          disabled: false,
          show: ''
        }, {
          name: 'Item',
          icon: <RiCake3Line />,
          to: '/menu-item',
          disabled: false,
          show: ''
        }, {
          name: 'Variasi Grup',
          icon: <RiSurveyLine />,
          to: '/menu-variasi-group',
          disabled: false,
          show: ''
        }, {
          name: 'Variasi Item',
          icon: <RiEqualizerLine />,
          to: '/menu-variasi-item',
          disabled: false,
          show: ''
        }, {
          name: 'Tag',
          icon: <RiPriceTag3Line />,
          to: '/menu-tag',
          disabled: false,
          show: ''
        }]
      }, {
        name: 'Organisasi',
        icon: <RiNodeTree />,
        to: '',
        open: false,
        disabled: false,
        show: '',
        child: [{
          name: 'Klien',
          icon: <RiHotelLine />,
          to: '/organisasi-klien',
          disabled: false,
          show: ''
        }, {
          name: 'Merek',
          icon: <RiStore3Line />,
          to: '/organisasi-merek',
          disabled: false,
          show: ''
        }, {
          name: 'Outlet',
          icon: <RiStoreLine />,
          to: '/organisasi-outlet',
          disabled: false,
          show: ''
        }, {
          name: 'Group',
          icon: <RiBuildingLine />,
          to: '/organisasi-group',
          disabled: false,
          show: ''
        }, {
          name: 'Label',
          icon: <RiPriceTag2Line />,
          to: '/organisasi-label',
          disabled: false,
          show: ''
        }, {
          name: 'Rekening',
          icon: <RiBankCardLine />,
          to: '/organisasi-rekening',
          disabled: false,
          show: ''
        }]
      }, {
        name: 'Pengguna',
        icon: <RiGroupLine />,
        to: '',
        open: false,
        disabled: false,
        show: '',
        child: [{
          name: 'Anggota',
          icon: <RiUserStarLine />,
          to: '/pengguna-anggota',
          disabled: false,
          show: ''
        }, {
          name: 'Admin',
          icon: <RiUserSettingsLine />,
          to: '/pengguna-admin',
          disabled: false,
          show: ''
        }]
      }, {
        name: 'Konfigurasi',
        icon: <RiListSettingsLine />,
        to: '',
        open: false,
        disabled: false,
        show: '',
        child: [{
          name: 'Bagikan Tautan',
          icon: <RiShareLine />,
          to: '/konfigurasi-bagikan-tautan',
          disabled: false,
          show: ''
        }, {
          name: 'FAQ',
          icon: <RiQuestionLine />,
          to: '/konfigurasi-faq',
          disabled: false,
          show: ''
        }, {
          name: 'Ketentuan Penggunaan',
          icon: <RiFileList3Line />,
          to: '/konfigurasi-ketentuan-penggunaan',
          disabled: false,
          show: ''
        }, {
          name: 'Kebijakan Privasi',
          icon: <RiShieldUserLine />,
          to: '/konfigurasi-kebijakan-privasi',
          disabled: false,
          show: ''
        }, {
          name: 'Penghapusan Data Pengguna',
          icon: <RiUserForbidLine />,
          to: '/konfigurasi-penghapusan-data-pengguna',
          disabled: false,
          show: ''
        }, {
          name: 'Hak Akses',
          icon: <RiShieldKeyholeLine />,
          to: '/konfigurasi-hak-akses',
          disabled: false,
          show: ''
        }, {
          name: 'Bank',
          icon: <RiBankLine />,
          to: '/konfigurasi-bank',
          disabled: false,
          show: ''
        }, {
          name: 'Pengaturan',
          icon: <RiSettings3Line />,
          to: '/konfigurasi-pengaturan',
          disabled: false,
          show: ''
        }]
      }],
      general: '',
      rangeDate: {
        startDate: newDate,
        endDate: endOfDay(newDate),
        // startDate: startOfMonth(new Date('2022-10-11')),
        // endDate: endOfMonth(new Date('2022-10-11')),
        key: 'selection',
      },
      rangeDateDash: {
        startDate: startOfMonth(newDate),
        endDate: endOfMonth(newDate),
        // startDate: startOfMonth(new Date('2022-10-11')),
        // endDate: endOfMonth(new Date('2022-10-11')),
        key: 'selection',
      },
    }
    
    this.authChange = '';
    this.unsubscribe = '';
    this.cancellableSetting = '';
    this.cancellablePrivilege = '';
    this.cancellableAccess = '';
    this.cancellableLogout = '';
    this.cancellableClient = '';
    this.cancellableBrand = '';
    this.cancellableOutlet = '';

    this.wrapperPro = React.createRef();
    this.loadSetting = this.loadSetting.bind(this);
    this.logOut = this.logOut.bind(this);
    this.updatePage = this.updatePage.bind(this);
    this.toggleNav = this.toggleNav.bind(this);
    this.toggleNavPro = this.toggleNavPro.bind(this);
    this.mouseDown = this.mouseDown.bind(this);
    this.selectChange = this.selectChange.bind(this);
    this.loadClient = this.loadClient.bind(this);
    this.loadBrand = this.loadBrand.bind(this);
    this.loadOutlet = this.loadOutlet.bind(this);
    this.autoPlayUpdate = this.autoPlayUpdate.bind(this);
    this.dateRangeChange = this.dateRangeChange.bind(this);
    this.dateRangeChangeDash = this.dateRangeChangeDash.bind(this);
  }

  componentDidMount(){
    // console.log(this.state.nav);

    this.loadSetting();

    this.authChange = onAuthStateChanged(auth, (user) => {
      this.setState({ navLoad: true });

      if(user){
        this.unsubscribe = onSnapshot(doc(db, 'user', user.uid), (doc) => {
          if(doc.exists()){
            const userData = doc.data();
            
            if(userData.userStatus && userData.userActive){
              this.cancellablePrivilege = makeCancellablePromise(
                getDocs(
                  query(
                    collection(db, 'user', user.uid, 'userPrivilege'), where('userPrivilegeActive', '==', true), where('userPrivilegeApp', '==', 'OrderGan'), limit(1)
                  )
                )
              );
          
              this.cancellablePrivilege.promise.then((priDoc) => {
                if(priDoc.size){
                  const dataPrivilege = priDoc.docs[0].data();
                  if(dataPrivilege.userPrivilegeAdmin){
                    let role = 'Admin OrderGan',
                        pageQuery = '';

                    if(dataPrivilege.userPrivilegeClient || dataPrivilege.userPrivilegeBrand || dataPrivilege.userPrivilegeOutlet){
                      if(dataPrivilege.userPrivilegeOutlet){
                        role = 'Admin Outlet';
                        pageQuery = query(collection(db, 'access'), where('accessOutlet', '==', true));
                      }else{
                        if(dataPrivilege.userPrivilegeBrand){
                          role = 'Admin Merek';
                          pageQuery = query(collection(db, 'access'), where('accessBrand', '==', true));
                        }else{
                          if(dataPrivilege.userPrivilegeClient){
                            role = 'Admin Klien';
                            pageQuery = query(collection(db, 'access'), where('accessClient', '==', true));
                          }
                          // else{
                          //   pageQuery = query(collection(db, 'access'));
                          // }
                        }
                      }

                      this.cancellableAccess = makeCancellablePromise(
                        getDocs(
                          pageQuery
                        )
                      );
                  
                      this.cancellableAccess.promise.then((accessSnapshot) => {
                        if(accessSnapshot.size){
                          let listNav = [...this.state.nav];

                          accessSnapshot.forEach((accessDoc) => {
                            const accessData = accessDoc.data();

                            let findPage = listNav.find(x => x.child.find(y => y.to === accessData.accessPage));

                            if(typeof findPage !== 'undefined'){
                              let findSub = findPage.child.find(x => x.to === accessData.accessPage);

                              if(typeof findSub !== 'undefined'){
                                if(role === 'Admin Klien'){
                                  findSub.show = accessData.accessClient;
                                }else if(role === 'Admin Merek'){
                                  findSub.show = accessData.accessBrand;
                                }else if(role === 'Admin Outlet'){
                                  findSub.show = accessData.accessOutlet;
                                }
                              }
                            }
                          });

                          listNav.forEach((value, index) => {
                            let show = false;

                            value.child.forEach((valChild, indChild) => {
                              if(valChild.show){
                                show = true;
                              }
                            });

                            value.show = show;
                          });

                          this.setState({
                            nav: listNav,
                            navLoad: false
                          });
                        }
                      }).catch((error) => {
                        console.error(error);
                        toast.error(() => (<>{error.code}<span>{error.message}</span></>));
                      });
                    }else{
                      let listNav = [...this.state.nav];

                      listNav.forEach((value, index) => {
                        value.child.forEach((valChild, indChild) => {
                          valChild.show = true;
                        });

                        value.show = true;
                      });

                      this.setState({
                        nav: listNav,
                        navLoad: false
                      });
                    }

                    this.setState({
                      signIn: true,
                      dataUser: {
                        uid: user.uid,
                        name: userData.userNameFirst + ' ' + userData.userNameLast,
                        email: userData.userEmail,
                        phone: userData.userPhone,
                        idPri: priDoc.docs[0].id,
                        idClient: dataPrivilege.userPrivilegeClient,
                        idBrand: dataPrivilege.userPrivilegeBrand,
                        idOutlet: dataPrivilege.userPrivilegeOutlet,
                        role: role
                      }
                    });
                  }else{
                    this.logOut(user.uid);
                  }
                }else{
                  this.logOut(user.uid);
                }
              }).catch((error) => {
                this.logOut(user.uid);
      
                console.error(error);
                toast.error(() => (<>{error.code}<span>{error.message}</span></>));
              });
            }else{
              this.logOut(user.uid);
            }
          }else{
            this.logOut(user.uid);
          }
        }, (error) => {
          this.logOut(user.uid);

          console.error(error);
          toast.error(() => (<>{error.code}<span>{error.message}</span></>));
        });
      }else{
        this.setState({
          navLoad: false,
          signIn: false,
          dataUser: {
            uid: '',
            name: '',
            email: '',
            phone: '',
            idPri: '',
            idClient: null,
            idBrand: null,
            idOutlet: null,
            role: ''
          },
          client: null,
          brand: null,
          outlet: null
        });
      }
    });
    
    this.loadClient();
    this.loadBrand();
    this.loadOutlet();

    document.addEventListener('mousedown', this.mouseDown);
  }

  componentDidUpdate(prevProps, prevState){
    if(this.state.signIn !== prevState.signIn){
      this.loadClient();
      this.loadBrand();
      this.loadOutlet();
    }

    if(this.state.client !== prevState.client){
      if(this.state.client !== null){
        if(!this.state.dataUser.idBrand){
          const listBrand = [...this.state.brandList],
                filterBrand = listBrand.filter(x => x.client === this.state.client.value);

          let findBrand = '';

          if(this.state.brand !== null){
            findBrand = filterBrand.find(x => x.value === this.state.brand.value);

            this.setState({ brand: findBrand ? findBrand : null });
          }else{
            this.setState({ brand: filterBrand.length === 1 ? filterBrand[0] : null });
          }

          this.setState({ brandFilter: filterBrand });
        }
      }else{
        this.setState({
          brand: null,
          outlet: null
        });
      }
    }

    if(this.state.brand !== prevState.brand){
      if(this.state.brand !== null){
        if(!this.state.dataUser.idOutlet){
          const listOutlet = [...this.state.outletList],
                filterOutlet = listOutlet.filter(x => x.brand === this.state.brand.value);

          this.setState({
            outlet: filterOutlet.length === 1 ? filterOutlet[0] : null,
            outletFilter: filterOutlet
          });
          
          if(this.state.dataUser.idClient === null){
            const findClient = this.state.clientList.find(x => x.value === this.state.brand.client);
  
            this.setState({
              client: findClient,
              clientDisabled: false
            });
          }
        }
      }else{
        this.setState({
          clientDisabled: false,
          outlet: null
        });
      }
    }
  }

  componentWillUnmount(){
    if(this.authChange){
      this.authChange();
    }

    if(this.unsubscribe){
      this.unsubscribe();
    }
    
    if(this.cancellableSetting){
      this.cancellableSetting.cancel();
    }
    
    if(this.cancellablePrivilege){
      this.cancellablePrivilege.cancel();
    }

    if(this.cancellableAccess){
      this.cancellableAccess.cancel();
    }
    
    if(this.cancellableLogout){
      this.cancellableLogout.cancel();
    }
    
    if(this.cancellableClient){
      this.cancellableClient.cancel();
    }
    
    if(this.cancellableBrand){
      this.cancellableBrand.cancel();
    }
    
    if(this.cancellableOutlet){
      this.cancellableOutlet.cancel();
    }

    document.removeEventListener('mousedown', this.mouseDown);
  }

  loadSetting(){
    this.cancellableSetting = makeCancellablePromise(
      getDocs(
        query(
          collection(db, 'setting'), where('settingApp', '==', 'ordergan'), limit(1)
        )
      )
    );

    this.cancellableSetting.promise.then((settingSnapshot) => {
      if(settingSnapshot.size){
        const settingDoc = settingSnapshot.docs[0],
              settingData = settingDoc.data();
        
        this.setState({
          general: {
            id: settingDoc.id,
            dineinRadius: settingData.settingDineinRadius,
            takeawayRadius: settingData.settingTakeawayRadius,
            dinein: settingData.settingChargeDinein,
            takeaway: settingData.settingChargeTakeaway,
            curbside: settingData.settingChargeCurbside,
            delivery: settingData.settingChargeDelivery,
            midUse: settingData.settingMidGateway,
            midMode: settingData.settingMidProduction,
            url: settingData.settingWeb,
            review: settingData.settingReview
          }
        });
      }
    }).catch((error) => {
      console.error(error);
      toast.error(() => (<>{error.code}<span>{error.message}</span></>));
    });
  }

  logOut(){
    this.cancellableLogout = makeCancellablePromise(signOut(auth));

    this.cancellableLogout.promise.then(() => {
      this.setState({ navPro: false });

      if(this.unsubscribe){
        this.unsubscribe();
      }
    }).catch((error) => {
      console.error(error);
      toast.error(() => (<>{error.code}<span>{error.message}</span></>));
    });
  }

  toggleNav(event){
    let index = '';

    if(typeof event.target !== 'undefined'){
      index = event.target.dataset.index;
    }else{
      index = this.state.nav.findIndex(x => x.name === event);
    }

    let theNav = [...this.state.nav],
        theOpen = theNav[index].open;

    if(theOpen){
      if(typeof event.target !== 'undefined'){
        theNav[index].open = false;
      }
    }else{
      theNav[index].open = true;
    }
    
    this.setState({ nav: theNav });
  }

  toggleNavPro(){
    this.setState({ navPro: !this.state.navPro });
  }

  updatePage(name, icon){
    this.setState({
      page: {
        name: name,
        icon: icon
      }
    });
  }

  mouseDown(event){
    if(this.wrapperPro.current && !this.wrapperPro.current.contains(event.target)){
      if(this.state.navPro){
        this.setState({ navPro: false });
      }
    }
  }

  selectChange(value, event){
    this.setState({ [event.name]: value });
  }

  loadClient(){
    if(this.state.signIn){
      if(this.state.dataUser.idClient === ''){
        const paramClient = {
          value: '',
          label: 'Tanpa Klien'
        };

        this.setState({
          clientList: [paramClient],
          client: paramClient,
          clientLoad: false
        });
      }else{
        if(this.state.dataUser.idClient){
          this.cancellableClient = makeCancellablePromise(
            getDoc(this.state.dataUser.idClient)
          );
        }else{
          this.cancellableClient = makeCancellablePromise(
            getDocs(
              query(
                collection(db, 'client'), where('clientStatus', '==', true)
              )
            )
          );
        }
    
        this.cancellableClient.promise.then((clientSnapshot) => {
          if(this.state.dataUser.idClient){
            if(clientSnapshot.exists()){
              const paramClient = {
                value: clientSnapshot.id,
                label: clientSnapshot.data().clientName
              };

              this.setState({
                clientList: [paramClient],
                client: paramClient
              });
            }

            this.setState({ clientLoad: false });
          }else{
            let listClient = [];

            listClient.push({
              value: '',
              label: 'Tanpa Klien'
            });
            
            if(clientSnapshot.size){
              clientSnapshot.forEach((clientDoc) => {
                const clientlData = clientDoc.data();
                
                listClient.push({
                  value: clientDoc.id,
                  label: clientlData.clientName
                });
              });
            }

            // console.log(listClient);
            
            this.setState({
              clientList: listClient,
              clientLoad: false
            });
          }
        }).catch((error) => {
          console.error(error);
    
          toast.error(() => (<>{error.code}<span>{error.message}</span></>));
          
          this.setState({ clientLoad: false });
        });
      }
    }
  }

  loadBrand(){
    if(this.state.signIn){
      if(this.state.dataUser.idBrand){
        this.cancellableBrand = makeCancellablePromise(
          getDoc(this.state.dataUser.idBrand)
        );
      }else{
        this.cancellableBrand = makeCancellablePromise(
          getDocs(
            query(
              collection(db, 'brand'), where('brandStatus', '==', true)
            )
          )
        );
      }
  
      this.cancellableBrand.promise.then((brandSnapshot) => {
        if(this.state.dataUser.idBrand){
          if(brandSnapshot.exists()){
            const paramBrand = {
              value: brandSnapshot.id,
              label: brandSnapshot.data().brandName
            };

            this.setState({
              brandList: [paramBrand],
              brand: paramBrand
            });
          }

          this.setState({ brandLoad: false });
        }else{
          if(brandSnapshot.size){
            let listBrand = [];
    
            brandSnapshot.forEach((brandDoc) => {
              const brandData = brandDoc.data();
              
              listBrand.push({
                value: brandDoc.id,
                label: brandData.brandName,
                client: brandData.brandClient.id || '',
                url: brandData.brandUrl,
                color: brandData.brandColor,
                img: brandData.brandImage,
                cover: brandData.brandCover,
                code: brandData.brandCode || '',
              });
            });
    
            this.setState({
              brandList: listBrand,
              brandFilter: listBrand
            });
          }
          
          this.setState({ brandLoad: false });
        }
      }).catch((error) => {
        console.error(error);
  
        toast.error(() => (<>{error.code}<span>{error.message}</span></>));
        
        this.setState({ brandLoad: false });
      });
    }
  }

  loadOutlet(){
    if(this.state.signIn){
      if(this.state.dataUser.idOutlet){
        this.cancellableOutlet = makeCancellablePromise(
          getDoc(this.state.dataUser.idOutlet)
        );
      }else{
        this.cancellableOutlet = makeCancellablePromise(
          getDocs(
            query(
              collection(db, 'outlet'), where('outletStatus', '==', true)
            )
          )
        );
      }
  
      this.cancellableOutlet.promise.then((outletSnapshot) => {
        if(this.state.dataUser.idOutlet){
          if(outletSnapshot.exists()){
            const outletData = outletSnapshot.data(),
                  paramOutlet = {
                    value: outletSnapshot.id,
                    label: outletData.outletName,
                    brand: outletData.outletBrand.id,
                    url: outletData.outletUrl,
                    type: outletData.outletType,
                    account: outletData.outletAccount ? outletData.outletAccount.id : '',
                  };

            this.setState({
              outletList: [paramOutlet],
              outlet: paramOutlet
            });
          }

          this.setState({ outletLoad: false });
        }else{
          if(outletSnapshot.size){
            let listOutlet = [];
    
            outletSnapshot.forEach((outletDoc) => {
              const outletData = outletDoc.data();
              
              listOutlet.push({
                value: outletDoc.id,
                label: outletData.outletName,
                brand: outletData.outletBrand.id,
                url: outletData.outletUrl,
                type: outletData.outletType,
                account: outletData.outletAccount ? outletData.outletAccount.id : '',
              });
            });
    
            this.setState({ outletList: listOutlet });
          }
          
          this.setState({ outletLoad: false });
        }
      }).catch((error) => {
        console.error(error);
  
        toast.error(() => (<>{error.code}<span>{error.message}</span></>));
        
        this.setState({ outletLoad: false });
      });
    }
  }

  autoPlayUpdate(){
    this.setState({ autoPlay: true });
  }

  dateRangeChange(ranges){
    this.setState({
      rangeDate: {
        startDate: ranges.selection.startDate,
        endDate: endOfDay(ranges.selection.endDate),
        key: 'selection',
      }
    });
  }

  dateRangeChangeDash(ranges){
    this.setState({
      rangeDateDash: {
        startDate: ranges.selection.startDate,
        endDate: endOfDay(ranges.selection.endDate),
        key: 'selection',
      }
    });
  }

  render(){
    let pageTitle = '';

    pageTitle = 'OrderGan Admin';

    if(this.state.page.name){
      pageTitle = pageTitle + ' • ' + this.state.page.name;
    }

    return(
      <>
        <Helmet>
          <title>{pageTitle}</title>
        </Helmet>

        {this.state.signIn === '' ? (
          <Loading onPage text="Memeriksa Autentikasi..." />
        ) : (
          <ul className={`floating main-lay ${this.state.signIn ? 'sign' : ''}`}>
            <li>
              <Link to="/">
                <Logo />
              </Link>

              {this.state.signIn ? (
                <ul className="floating">
                  {this.state.nav.map((value, index) => value.show ? (
                    <li key={`nav-${index}`} className={value.open ? 'open' : ''}>
                      {value.child.length ? (
                        <>
                          <button type="button" onClick={this.toggleNav} data-index={index}>
                            {value.icon}{value.name}<RiArrowDownSFill className="caret" />
                          </button>
                          {value.open ? (
                            <ul className="floating child">
                              {value.child.map((val, ind) => val.show ? (
                                <li key={`nav-${index}-${ind}`}>
                                  <NavLink to={val.to} disabled={val.disabled}>
                                    {val.icon}{val.name}
                                  </NavLink>
                                </li>
                              ) : '')}
                            </ul>
                          ) : ''}
                        </>
                      ) : (
                        <NavLink to={value.to} disabled={value.disabled}>
                          {value.icon}{value.name}
                        </NavLink>
                      )}
                    </li>
                  ) : '')}
                </ul>
              ) : ''}

              <div className="copy">&copy; 2023 - 2024 Hak Cipta Terpelihara <Link to="/">Admin.OrderGan.com</Link>.</div>
            </li>
            <li>
              {this.state.signIn ? (
                <>
                  <header>
                    <div className="page-title">
                      {this.state.page.icon} {this.state.page.name}
                    </div>
                    
                    <div className="nav-pro" ref={this.wrapperPro}>
                      <button type="button" onClick={this.toggleNavPro} className={this.state.navPro ? 'open' : ''}>
                        {this.state.dataUser.name} &bull; {this.state.dataUser.role}
                        {this.state.dataUser.idClient !== null ? (
                          <div>
                            {this.state.dataUser.idClient !== null ? (this.state.client ? this.state.client.label : 'Tanpa Klien') : ''}
                            {this.state.dataUser.idBrand ? (this.state.brand ? ' / ' + this.state.brand.label : '') : ''}
                            {this.state.dataUser.idOutlet ? (this.state.outlet ? ' / ' + this.state.outlet.label : '') : ''}
                          </div>
                        ) : (
                          <div>
                            {this.state.dataUser.role === 'Admin OrderGan' ? 'Global' : ''}
                          </div>
                        )}
                        <RiUserLine />
                      </button>

                      {this.state.navPro ? (
                        <ul>
                          <li>
                            <Link to="/profil">Profil <RiUserSettingsLine /></Link>
                          </li>
                          <li>
                            <Link to="/tagihan">Tagihan <RiCashFill /></Link>
                          </li>
                          <li>
                            <button type="button" onClick={this.logOut}>Keluar <RiLoginCircleLine /></button>
                          </li>
                        </ul>
                      ) : ''}
                    </div>
                  </header>
                  <div className="pad-head"></div>
                  
                  <div className="role-select box-white">
                    <ul className="forming floating">
                      <li className="lay-333">
                        <SelectBox placeholder={`${this.state.clientLoad ? 'Memuat ' : ''}Klien`} name="client" value={this.state.client} onChange={this.selectChange} options={this.state.clientList} disabled={this.state.clientLoad || this.state.clientDisabled || this.state.dataUser.idClient !== null} isClearable />
                      </li>
                      <li className="lay-333">
                        <SelectBox placeholder={`${this.state.brandLoad ? 'Memuat ' : ''}Merek`} name="brand" value={this.state.brand} onChange={this.selectChange} options={this.state.client !== null ? this.state.brandFilter : this.state.brandList} disabled={this.state.brandLoad || this.state.dataUser.idBrand} isClearable />
                      </li>
                      <li className="lay-333">
                        <SelectBox placeholder={`${this.state.outletLoad ? 'Memuat ' : ''}Outlet ${!this.state.outletLoad && this.state.brand ? '' : '(pilih merek dahulu)'}`} name="outlet" value={this.state.outlet} onChange={this.selectChange} options={this.state.outletFilter} disabled={!this.state.brand || this.state.outletLoad || this.state.dataUser.idOutlet} isClearable />
                      </li>
                    </ul>
                  </div>
                </>
              ) : ''}

              {this.state.navLoad ? 'LoadingNav...' : (
                <Suspense fallback={
                  <Loading onSection text="Memuat Halaman..." />
                }>
                  <Routes>
                    <Route path="/login" element={
                      this.state.signIn ? (<Navigate to="/dasbor-utama" />) : (<Login />)
                    } />
                    {this.state.nav[0].child[0].show ? (
                      <Route path="/tagihan" element={
                        this.state.signIn ? (<Billing updatePage={this.updatePage} clientLoad={this.state.clientLoad} clientList={this.state.clientList} client={this.state.client} brandLoad={this.state.brandLoad} brandList={this.state.brandList} brand={this.state.brand} outletLoad={this.state.outletLoad} outletList={this.state.outletList} outlet={this.state.outlet} rangeDate={this.state.rangeDate} dateRangeChange={this.dateRangeChange} />) : (<Navigate to="/login" />)
                      } />
                    ) : ''}
                    {this.state.nav[0].child[0].show ? (
                      <Route path="/dasbor-utama" element={
                        this.state.signIn ? (<Dashboard updatePage={this.updatePage} nav={this.state.nav[0]} child={0} toggleNav={this.toggleNav} />) : (<Navigate to="/login" />)
                      } />
                    ) : ''}
                    {this.state.nav[0].child[1].show ? (
                      <Route path="/dasbor-transaksi" element={
                        this.state.signIn ? (<DashboardTransaction updatePage={this.updatePage} nav={this.state.nav[0]} child={1} toggleNav={this.toggleNav} clientLoad={this.state.clientLoad} clientList={this.state.clientList} client={this.state.client} brandLoad={this.state.brandLoad} brandList={this.state.brandList} brand={this.state.brand} outletLoad={this.state.outletLoad} outletList={this.state.outletList} outlet={this.state.outlet} rangeDate={this.state.rangeDateDash} dateRangeChange={this.dateRangeChangeDash} />) : (<Navigate to="/login" />)
                      } />
                    ) : ''}
                    {this.state.nav[0].child[2].show ? (
                      <Route path="/dasbor-keuangan" element={
                        this.state.signIn ? (<DashboardFinance updatePage={this.updatePage} nav={this.state.nav[0]} child={2} toggleNav={this.toggleNav} clientLoad={this.state.clientLoad} clientList={this.state.clientList} client={this.state.client} brandLoad={this.state.brandLoad} brandList={this.state.brandList} brand={this.state.brand} outletLoad={this.state.outletLoad} outletList={this.state.outletList} outlet={this.state.outlet} rangeDate={this.state.rangeDateDash} dateRangeChange={this.dateRangeChangeDash} />) : (<Navigate to="/login" />)
                      } />
                    ) : ''}
                    {this.state.nav[0].child[3].show ? (
                      <Route path="/dasbor-penjualan-item" element={
                        this.state.signIn ? (<DashboardItem updatePage={this.updatePage} nav={this.state.nav[0]} child={3} toggleNav={this.toggleNav} clientLoad={this.state.clientLoad} clientList={this.state.clientList} client={this.state.client} brandLoad={this.state.brandLoad} brandList={this.state.brandList} brand={this.state.brand} outletLoad={this.state.outletLoad} outletList={this.state.outletList} outlet={this.state.outlet} rangeDate={this.state.rangeDateDash} dateRangeChange={this.dateRangeChangeDash} />) : (<Navigate to="/login" />)
                      } />
                    ) : ''}
                    {this.state.nav[0].child[4].show ? (
                      <Route path="/dasbor-penjualan-varian" element={
                        this.state.signIn ? (<DashboardVariant updatePage={this.updatePage} nav={this.state.nav[0]} child={4} toggleNav={this.toggleNav} clientLoad={this.state.clientLoad} clientList={this.state.clientList} client={this.state.client} brandLoad={this.state.brandLoad} brandList={this.state.brandList} brand={this.state.brand} outletLoad={this.state.outletLoad} outletList={this.state.outletList} outlet={this.state.outlet} rangeDate={this.state.rangeDateDash} dateRangeChange={this.dateRangeChangeDash} />) : (<Navigate to="/login" />)
                      } />
                    ) : ''}
                    {this.state.nav[0].child[5].show ? (
                      <Route path="/dasbor-penilaian-item" element={
                        this.state.signIn ? (<DashboardItemRate updatePage={this.updatePage} nav={this.state.nav[0]} child={5} toggleNav={this.toggleNav} clientLoad={this.state.clientLoad} clientList={this.state.clientList} client={this.state.client} brandLoad={this.state.brandLoad} brandList={this.state.brandList} brand={this.state.brand} outletLoad={this.state.outletLoad} outletList={this.state.outletList} outlet={this.state.outlet} />) : (<Navigate to="/login" />)
                      } />
                    ) : ''}
                    {this.state.nav[0].child[5].show ? (
                      <Route path="/dasbor-penilaian-varian" element={
                        this.state.signIn ? (<DashboardVariantRate updatePage={this.updatePage} nav={this.state.nav[0]} child={6} toggleNav={this.toggleNav} clientLoad={this.state.clientLoad} clientList={this.state.clientList} client={this.state.client} brandLoad={this.state.brandLoad} brandList={this.state.brandList} brand={this.state.brand} outletLoad={this.state.outletLoad} outletList={this.state.outletList} outlet={this.state.outlet} />) : (<Navigate to="/login" />)
                      } />
                    ) : ''}
                    {this.state.nav[0].child[7].show ? (
                      <Route path="/dasbor-pelanggan" element={
                        this.state.signIn ? (<DashboardUser updatePage={this.updatePage} nav={this.state.nav[0]} child={7} toggleNav={this.toggleNav} clientLoad={this.state.clientLoad} clientList={this.state.clientList} client={this.state.client} brandLoad={this.state.brandLoad} brandList={this.state.brandList} brand={this.state.brand} outletLoad={this.state.outletLoad} outletList={this.state.outletList} outlet={this.state.outlet} rangeDate={this.state.rangeDateDash} dateRangeChange={this.dateRangeChangeDash} />) : (<Navigate to="/login" />)
                      } />
                    ) : ''}
                    {this.state.nav[0].child[8].show ? (
                      <Route path="/dasbor-tipe" element={
                        this.state.signIn ? (<DashboardType updatePage={this.updatePage} nav={this.state.nav[0]} child={8} toggleNav={this.toggleNav} clientLoad={this.state.clientLoad} clientList={this.state.clientList} client={this.state.client} brandLoad={this.state.brandLoad} brandList={this.state.brandList} brand={this.state.brand} outletLoad={this.state.outletLoad} outletList={this.state.outletList} outlet={this.state.outlet} rangeDate={this.state.rangeDateDash} dateRangeChange={this.dateRangeChangeDash} />) : (<Navigate to="/login" />)
                      } />
                    ) : ''}
                    {this.state.nav[0].child[9].show ? (
                      <Route path="/dasbor-pembayaran" element={
                        this.state.signIn ? (<DashboardPayment updatePage={this.updatePage} nav={this.state.nav[0]} child={9} toggleNav={this.toggleNav} clientLoad={this.state.clientLoad} clientList={this.state.clientList} client={this.state.client} brandLoad={this.state.brandLoad} brandList={this.state.brandList} brand={this.state.brand} outletLoad={this.state.outletLoad} outletList={this.state.outletList} outlet={this.state.outlet} rangeDate={this.state.rangeDateDash} dateRangeChange={this.dateRangeChangeDash} />) : (<Navigate to="/login" />)
                      } />
                    ) : ''}
                    {this.state.nav[0].child[10].show ? (
                      <Route path="/dasbor-meja" element={
                        this.state.signIn ? (<DashboardTable updatePage={this.updatePage} nav={this.state.nav[0]} child={10} toggleNav={this.toggleNav} clientLoad={this.state.clientLoad} clientList={this.state.clientList} client={this.state.client} brandLoad={this.state.brandLoad} brandList={this.state.brandList} brand={this.state.brand} outletLoad={this.state.outletLoad} outletList={this.state.outletList} outlet={this.state.outlet} rangeDate={this.state.rangeDateDash} dateRangeChange={this.dateRangeChangeDash} />) : (<Navigate to="/login" />)
                      } />
                    ) : ''}
                    {this.state.nav[1].child[0].show ? (
                      <Route path="/orderan-kasir" element={
                        this.state.signIn ? (<OrderLive updatePage={this.updatePage} nav={this.state.nav[1]} child={0} toggleNav={this.toggleNav} autoPlay={this.state.autoPlay} autoPlayUpdate={this.autoPlayUpdate} brandLoad={this.state.brandLoad} brandList={this.state.brandList} brand={this.state.brand} outletLoad={this.state.outletLoad} outlet={this.state.outlet} />) : (<Navigate to="/login" />)
                      } />
                    ) : ''}
                    {this.state.nav[1].child[1].show ? (
                      <Route path="/orderan-dapur" element={
                        this.state.signIn ? (<OrderKitchen updatePage={this.updatePage} nav={this.state.nav[1]} child={1} toggleNav={this.toggleNav} autoPlay={this.state.autoPlay} autoPlayUpdate={this.autoPlayUpdate} brandLoad={this.state.brandLoad} brandList={this.state.brandList} brand={this.state.brand} outletLoad={this.state.outletLoad} outlet={this.state.outlet} />) : (<Navigate to="/login" />)
                      } />
                    ) : ''}
                    {this.state.nav[1].child[2].show ? (
                      <Route path="/orderan-riwayat" element={
                        this.state.signIn ? (<OrderHistory updatePage={this.updatePage} nav={this.state.nav[1]} child={2} toggleNav={this.toggleNav} clientLoad={this.state.clientLoad} clientList={this.state.clientList} client={this.state.client} brandLoad={this.state.brandLoad} brandList={this.state.brandList} brand={this.state.brand} outletLoad={this.state.outletLoad} outletList={this.state.outletList} outlet={this.state.outlet} rangeDate={this.state.rangeDate} dateRangeChange={this.dateRangeChange} />) : (<Navigate to="/login" />)
                      } />
                    ) : ''}
                    {this.state.nav[2].child[0].show ? (
                      <Route path="/laporan-transaksi" element={
                        this.state.signIn ? (<ReportTransaction updatePage={this.updatePage} nav={this.state.nav[2]} child={0} toggleNav={this.toggleNav} clientLoad={this.state.clientLoad} clientList={this.state.clientList} client={this.state.client} brandLoad={this.state.brandLoad} brandList={this.state.brandList} brand={this.state.brand} outletLoad={this.state.outletLoad} outletList={this.state.outletList} outlet={this.state.outlet} rangeDate={this.state.rangeDate} dateRangeChange={this.dateRangeChange} />) : (<Navigate to="/login" />)
                      } />
                    ) : ''}
                    {this.state.nav[2].child[1].show ? (
                      <Route path="/laporan-item" element={
                        this.state.signIn ? (<ReportItem updatePage={this.updatePage} nav={this.state.nav[2]} child={1} toggleNav={this.toggleNav} clientLoad={this.state.clientLoad} clientList={this.state.clientList} client={this.state.client} brandLoad={this.state.brandLoad} brandList={this.state.brandList} brand={this.state.brand} outletLoad={this.state.outletLoad} outletList={this.state.outletList} outlet={this.state.outlet} rangeDate={this.state.rangeDate} dateRangeChange={this.dateRangeChange} />) : (<Navigate to="/login" />)
                      } />
                    ) : ''}
                    {this.state.nav[2].child[2].show ? (
                      <Route path="/laporan-penjualan" element={
                        this.state.signIn ? (<ReportSell updatePage={this.updatePage} nav={this.state.nav[2]} child={2} toggleNav={this.toggleNav} clientLoad={this.state.clientLoad} clientList={this.state.clientList} client={this.state.client} brandLoad={this.state.brandLoad} brandList={this.state.brandList} brand={this.state.brand} outletLoad={this.state.outletLoad} outletList={this.state.outletList} outlet={this.state.outlet} rangeDate={this.state.rangeDate} dateRangeChange={this.dateRangeChange} />) : (<Navigate to="/login" />)
                      } />
                    ) : ''}
                    {this.state.nav[2].child[3].show ? (
                      <Route path="/laporan-pendapatan" element={
                        this.state.signIn ? (<ReportIncome updatePage={this.updatePage} nav={this.state.nav[2]} child={3} toggleNav={this.toggleNav} clientLoad={this.state.clientLoad} clientList={this.state.clientList} client={this.state.client} brandLoad={this.state.brandLoad} brandList={this.state.brandList} brand={this.state.brand} outletLoad={this.state.outletLoad} outletList={this.state.outletList} outlet={this.state.outlet} rangeDate={this.state.rangeDate} dateRangeChange={this.dateRangeChange} />) : (<Navigate to="/login" />)
                      } />
                    ) : ''}
                    {this.state.nav[2].child[4].show ? (
                      <Route path="/laporan-biaya" element={
                        this.state.signIn ? (<ReportOthers updatePage={this.updatePage} nav={this.state.nav[2]} child={4} toggleNav={this.toggleNav} clientLoad={this.state.clientLoad} clientList={this.state.clientList} client={this.state.client} brandLoad={this.state.brandLoad} brandList={this.state.brandList} brand={this.state.brand} outletLoad={this.state.outletLoad} outletList={this.state.outletList} outlet={this.state.outlet} rangeDate={this.state.rangeDate} dateRangeChange={this.dateRangeChange} />) : (<Navigate to="/login" />)
                      } />
                    ) : ''}
                    {this.state.nav[2].child[5].show ? (
                      <Route path="/laporan-settlement" element={
                        this.state.signIn ? (<ReportSettlement updatePage={this.updatePage} nav={this.state.nav[2]} child={5} toggleNav={this.toggleNav} clientLoad={this.state.clientLoad} clientList={this.state.clientList} client={this.state.client} brandLoad={this.state.brandLoad} brandList={this.state.brandList} brand={this.state.brand} outletLoad={this.state.outletLoad} outletList={this.state.outletList} outlet={this.state.outlet} rangeDate={this.state.rangeDate} dateRangeChange={this.dateRangeChange} />) : (<Navigate to="/login" />)
                      } />
                    ) : ''}
                    {this.state.nav[2].child[6].show ? (
                      <Route path="/laporan-pencairan" element={
                        this.state.signIn ? (<ReportDisbursement updatePage={this.updatePage} nav={this.state.nav[2]} child={6} toggleNav={this.toggleNav} clientLoad={this.state.clientLoad} clientList={this.state.clientList} client={this.state.client} brandLoad={this.state.brandLoad} brandList={this.state.brandList} brand={this.state.brand} outletLoad={this.state.outletLoad} outletList={this.state.outletList} outlet={this.state.outlet} rangeDate={this.state.rangeDate} dateRangeChange={this.dateRangeChange} />) : (<Navigate to="/login" />)
                      } />
                    ) : ''}
                    {/* Operational Availability */}
                    {this.state.nav[3].child[0].show ? (
                      <Route path="/operasional-item" element={
                        this.state.signIn ? (<OperationalAvailability updatePage={this.updatePage} nav={this.state.nav[3]} child={0} toggleNav={this.toggleNav} outletLoad={this.state.outletLoad} outlet={this.state.outlet} />) : (<Navigate to="/login" />)
                      } />
                    ) : ''}
                    {/* Operational Customization */}
                    {this.state.nav[3].child[1].show ? (
                      <Route path="/operasional-variasi" element={
                        this.state.signIn ? (<OperationalCustom updatePage={this.updatePage} nav={this.state.nav[3]} child={1} toggleNav={this.toggleNav} outletLoad={this.state.outletLoad} outlet={this.state.outlet} />) : (<Navigate to="/login" />)
                      } />
                    ) : ''}
                    {/* Operational Kitchen */}
                    {this.state.nav[3].child[0].show ? (
                      <>
                        <Route path="/operasional-dapur" element={
                          this.state.signIn ? (<OperationalKitchen updatePage={this.updatePage} nav={this.state.nav[3]} child={2} toggleNav={this.toggleNav} brandLoad={this.state.brandLoad} brand={this.state.brand} outletLoad={this.state.outletLoad} outlet={this.state.outlet} />) : (<Navigate to="/login" />)
                        } />
                        <Route path="/operasional-dapur/tambah" element={
                          this.state.signIn ? (<OperationalKitchenForm updatePage={this.updatePage} nav={this.state.nav[3]} child={2} toggleNav={this.toggleNav} formMode="Add" outlet={this.state.outlet} />) : (<Navigate to="/login" />)
                        } />
                        <Route path="/operasional-dapur/edit/:idKitchen" element={
                          this.state.signIn ? (<OperationalKitchenForm updatePage={this.updatePage} nav={this.state.nav[3]} child={2} toggleNav={this.toggleNav} formMode="Edit" outlet={this.state.outlet} />) : (<Navigate to="/login" />)
                        } />
                      </>
                    ) : ''}
                    {/* Operational QR */}
                    {this.state.nav[3].child[2].show ? (
                      <>
                        <Route path="/operasional-kode-qr" element={
                          this.state.signIn ? (<OperationalQr updatePage={this.updatePage} nav={this.state.nav[3]} child={3} toggleNav={this.toggleNav} general={this.state.general} brandLoad={this.state.brandLoad} brand={this.state.brand} outletLoad={this.state.outletLoad} outlet={this.state.outlet} />) : (<Navigate to="/login" />)
                        } />
                        <Route path="/operasional-kode-qr/tambah" element={
                          this.state.signIn ? (<OperationalQrForm updatePage={this.updatePage} nav={this.state.nav[3]} child={3} toggleNav={this.toggleNav} formMode="Add" outlet={this.state.outlet} />) : (<Navigate to="/login" />)
                        } />
                        <Route path="/operasional-kode-qr/edit/:idQr" element={
                          this.state.signIn ? (<OperationalQrForm updatePage={this.updatePage} nav={this.state.nav[3]} child={3} toggleNav={this.toggleNav} formMode="Edit" outlet={this.state.outlet} />) : (<Navigate to="/login" />)
                        } />
                        <Route path="/operasional-kode-qr/edit-group/:idGroupQr" element={
                          this.state.signIn ? (<OperationalQrForm updatePage={this.updatePage} nav={this.state.nav[3]} child={3} toggleNav={this.toggleNav} formMode="Edit" outlet={this.state.outlet} />) : (<Navigate to="/login" />)
                        } />
                      </>
                    ) : ''}
                    {/* Operational Payment */}
                    {/* {this.state.nav[3].child[3].show ? (
                      <>
                        <Route path="/operasional-pembayaran" element={
                          this.state.signIn ? (<OperationalPayment updatePage={this.updatePage} nav={this.state.nav[3]} child={3} toggleNav={this.toggleNav} general={this.state.general} brandLoad={this.state.brandLoad} brand={this.state.brand} outletLoad={this.state.outletLoad} outlet={this.state.outlet} />) : (<Navigate to="/login" />)
                        } />
                        <Route path="/operasional-pembayaran/tambah" element={
                          this.state.signIn ? (<OperationalPaymentForm updatePage={this.updatePage} nav={this.state.nav[3]} child={3} toggleNav={this.toggleNav} formMode="Add" brand={this.state.brand} outlet={this.state.outlet} />) : (<Navigate to="/login" />)
                        } />
                        <Route path="/operasional-pembayaran/edit/:idPay" element={
                          this.state.signIn ? (<OperationalPaymentForm updatePage={this.updatePage} nav={this.state.nav[3]} child={3} toggleNav={this.toggleNav} formMode="Edit" brand={this.state.brand} outlet={this.state.outlet} />) : (<Navigate to="/login" />)
                        } />
                      </>
                    ) : ''} */}
                    {/* Operational Loyalty */}
                    {this.state.nav[3].child[3].show ? (
                      <>
                        <Route path="/operasional-loyalty" element={
                          this.state.signIn ? (<OperationalLoyalty updatePage={this.updatePage} nav={this.state.nav[3]} child={4} toggleNav={this.toggleNav} general={this.state.general} client={this.state.client} clientList={this.state.clientList} clientLoad={this.state.clientLoad} brand={this.state.brand} brandList={this.state.brandList} brandLoad={this.state.brandLoad} outlet={this.state.outlet} outletList={this.state.outletList} outletLoad={this.state.outletLoad} />) : (<Navigate to="/login" />)
                        } />
                        <Route path="/operasional-loyalty/tambah" element={
                          this.state.signIn ? (<OperationalLoyaltyForm updatePage={this.updatePage} nav={this.state.nav[3]} child={4} toggleNav={this.toggleNav} formMode="Add" brand={this.state.brand} brandList={this.state.brandList}  brandLoad={this.state.brandLoad} outlet={this.state.outlet} outletList={this.state.outletList} outletLoad={this.state.outletLoad} />) : (<Navigate to="/login" />)
                        } />
                        <Route path="/operasional-loyalty/edit/:idLoyalty" element={
                          this.state.signIn ? (<OperationalLoyaltyForm updatePage={this.updatePage} nav={this.state.nav[3]} child={4} toggleNav={this.toggleNav} formMode="Edit" brand={this.state.brand} brandList={this.state.brandList} brandLoad={this.state.brandLoad} outlet={this.state.outlet} outletList={this.state.outletList} outletLoad={this.state.outletLoad} />) : (<Navigate to="/login" />)
                        } />
                        <Route path="/operasional-loyalty/stamp/:idLoyalty" element={
                          this.state.signIn ? (<OperationalLoyaltyStamp updatePage={this.updatePage} nav={this.state.nav[3]} child={4} toggleNav={this.toggleNav} />) : (<Navigate to="/login" />)
                        } />
                      </>
                    ) : ''}
                    {/* Menu Editor */}
                    {this.state.nav[4].child[0].show ? (
                      <>
                        <Route path="/menu-editor" element={
                          this.state.signIn ? (<MenuEditor updatePage={this.updatePage} nav={this.state.nav[4]} child={0} toggleNav={this.toggleNav} general={this.state.general} brandLoad={this.state.brandLoad} brand={this.state.brand} outletLoad={this.state.outletLoad} outlet={this.state.outlet} />) : (<Navigate to="/login" />)
                        } />
                        {/* <Route path="/menu-editor/tambah" element={
                          this.state.signIn ? (<MenuCategoryForm updatePage={this.updatePage} nav={this.state.nav[4]} child={0} toggleNav={this.toggleNav} formMode="Add" />) : (<Navigate to="/login" />)
                        } />
                        <Route path="/menu-kategori/edit/:idKategori" element={
                          this.state.signIn ? (<MenuCategoryForm updatePage={this.updatePage} nav={this.state.nav[4]} child={0} toggleNav={this.toggleNav} formMode="Edit" />) : (<Navigate to="/login" />)
                        } /> */}
                      </>
                    ) : ''}
                    {/* Menu Category */}
                    {this.state.nav[4].child[1].show ? (
                      <>
                        <Route path="/menu-kategori" element={
                          this.state.signIn ? (<MenuCategory updatePage={this.updatePage} nav={this.state.nav[4]} child={1} toggleNav={this.toggleNav} general={this.state.general} brandLoad={this.state.brandLoad} brandList={this.state.brandList} client={this.state.client} brand={this.state.brand} />) : (<Navigate to="/login" />)
                        } />
                        <Route path="/menu-kategori/tambah" element={
                          this.state.signIn ? (<MenuCategoryForm updatePage={this.updatePage} nav={this.state.nav[4]} child={1} toggleNav={this.toggleNav} formMode="Add" brandLoad={this.state.brandLoad} brandList={this.state.brandList} client={this.state.client} brand={this.state.brand} />) : (<Navigate to="/login" />)
                        } />
                        <Route path="/menu-kategori/edit/:idKategori" element={
                          this.state.signIn ? (<MenuCategoryForm updatePage={this.updatePage} nav={this.state.nav[4]} child={1} toggleNav={this.toggleNav} formMode="Edit" brandLoad={this.state.brandLoad} brandList={this.state.brandList} />) : (<Navigate to="/login" />)
                        } />
                      </>
                    ) : ''}
                    {/* Menu Item */}
                    {this.state.nav[4].child[2].show ? (
                      <>
                        <Route path="/menu-item" element={
                          this.state.signIn ? (<MenuItem updatePage={this.updatePage} nav={this.state.nav[4]} child={2} toggleNav={this.toggleNav} general={this.state.general} brandLoad={this.state.brandLoad} brandList={this.state.brandList} client={this.state.client} brand={this.state.brand} />) : (<Navigate to="/login" />)
                        } />
                        <Route path="/menu-item/tambah" element={
                          this.state.signIn ? (<MenuItemForm updatePage={this.updatePage} nav={this.state.nav[4]} child={2} toggleNav={this.toggleNav} formMode="Add" brandLoad={this.state.brandLoad} brandList={this.state.brandList} client={this.state.client} brand={this.state.brand} />) : (<Navigate to="/login" />)
                        } />
                        <Route path="/menu-item/edit/:idItem" element={
                          this.state.signIn ? (<MenuItemForm updatePage={this.updatePage} nav={this.state.nav[4]} child={2} toggleNav={this.toggleNav} formMode="Edit" brandLoad={this.state.brandLoad} brandList={this.state.brandList} />) : (<Navigate to="/login" />)
                        } />
                      </>
                    ) : ''}
                    {/* Menu Customization Group */}
                    {this.state.nav[4].child[3].show ? (
                      <>
                        <Route path="/menu-variasi-group" element={
                          this.state.signIn ? (<MenuCustomizationGroup updatePage={this.updatePage} nav={this.state.nav[4]} child={3} toggleNav={this.toggleNav} general={this.state.general} brandLoad={this.state.brandLoad} brandList={this.state.brandList} client={this.state.client} brand={this.state.brand} />) : (<Navigate to="/login" />)
                        } />
                        <Route path="/menu-variasi-group/tambah" element={
                          this.state.signIn ? (<MenuCustomizationGroupForm updatePage={this.updatePage} nav={this.state.nav[4]} child={3} toggleNav={this.toggleNav} formMode="Add" brandLoad={this.state.brandLoad} brandList={this.state.brandList} client={this.state.client} brand={this.state.brand} />) : (<Navigate to="/login" />)
                        } />
                        <Route path="/menu-variasi-group/edit/:idCustomizationGroup" element={
                          this.state.signIn ? (<MenuCustomizationGroupForm updatePage={this.updatePage} nav={this.state.nav[4]} child={3} toggleNav={this.toggleNav} formMode="Edit" brandLoad={this.state.brandLoad} brandList={this.state.brandList} />) : (<Navigate to="/login" />)
                        } />
                      </>
                    ) : ''}
                    {/* Menu Customization Item */}
                    {this.state.nav[4].child[4].show ? (
                      <>
                        <Route path="/menu-variasi-item" element={
                          this.state.signIn ? (<MenuCustomizationItem updatePage={this.updatePage} nav={this.state.nav[4]} child={4} toggleNav={this.toggleNav} general={this.state.general} brandLoad={this.state.brandLoad} brandList={this.state.brandList} client={this.state.client} brand={this.state.brand} />) : (<Navigate to="/login" />)
                        } />
                        <Route path="/menu-variasi-item/tambah" element={
                          this.state.signIn ? (<MenuCustomizationItemForm updatePage={this.updatePage} nav={this.state.nav[4]} child={4} toggleNav={this.toggleNav} formMode="Add" brandLoad={this.state.brandLoad} brandList={this.state.brandList} client={this.state.client} brand={this.state.brand} />) : (<Navigate to="/login" />)
                        } />
                        <Route path="/menu-variasi-item/edit/:idCustomizationItem" element={
                          this.state.signIn ? (<MenuCustomizationItemForm updatePage={this.updatePage} nav={this.state.nav[4]} child={4} toggleNav={this.toggleNav} formMode="Edit" brandLoad={this.state.brandLoad} brandList={this.state.brandList} />) : (<Navigate to="/login" />)
                        } />
                      </>
                    ) : ''}
                    {/* Menu Tag */}
                    {this.state.nav[4].child[5].show ? (
                      <>
                        <Route path="/menu-tag" element={
                          this.state.signIn ? (<MenuTag updatePage={this.updatePage} nav={this.state.nav[4]} child={5} toggleNav={this.toggleNav} general={this.state.general} brandLoad={this.state.brandLoad} brandList={this.state.brandList} client={this.state.client} brand={this.state.brand} />) : (<Navigate to="/login" />)
                        } />
                        <Route path="/menu-tag/tambah" element={
                          this.state.signIn ? (<MenuTagForm updatePage={this.updatePage} nav={this.state.nav[4]} child={5} toggleNav={this.toggleNav} formMode="Add" brandLoad={this.state.brandLoad} brandList={this.state.brandList} client={this.state.client} brand={this.state.brand} />) : (<Navigate to="/login" />)
                        } />
                        <Route path="/menu-tag/edit/:idTag" element={
                          this.state.signIn ? (<MenuTagForm updatePage={this.updatePage} nav={this.state.nav[4]} child={5} toggleNav={this.toggleNav} formMode="Edit" brandLoad={this.state.brandLoad} brandList={this.state.brandList} />) : (<Navigate to="/login" />)
                        } />
                      </>
                    ) : ''}
                    {/* Organisasi Klien */}
                    {this.state.nav[5].child[0].show ? (
                      <>
                        <Route path="/organisasi-klien" element={
                          this.state.signIn ? (<OrganizationClient updatePage={this.updatePage} nav={this.state.nav[5]} child={0} toggleNav={this.toggleNav} />) : (<Navigate to="/login" />)
                        } />
                        <Route path="/organisasi-klien/tambah" element={
                          this.state.signIn ? (<OrganizationClientForm updatePage={this.updatePage} nav={this.state.nav[5]} child={0} toggleNav={this.toggleNav} formMode="Add" />) : (<Navigate to="/login" />)
                        } />
                        <Route path="/organisasi-klien/edit/:idKlien" element={
                          this.state.signIn ? (<OrganizationClientForm updatePage={this.updatePage} nav={this.state.nav[5]} child={0} toggleNav={this.toggleNav} formMode="Edit" />) : (<Navigate to="/login" />)
                        } />
                      </>
                    ) : ''}
                    {/* Organisasi Merek */}
                    {this.state.nav[5].child[1].show ? (
                      <>
                        <Route path="/organisasi-merek" element={
                          this.state.signIn ? (<OrganizationBrand updatePage={this.updatePage} nav={this.state.nav[5]} child={1} toggleNav={this.toggleNav} general={this.state.general} clientLoad={this.state.clientLoad} clientList={this.state.clientList} client={this.state.client} />) : (<Navigate to="/login" />)
                        } />
                        <Route path="/organisasi-merek/tambah" element={
                          this.state.signIn ? (<OrganizationBrandForm updatePage={this.updatePage} nav={this.state.nav[5]} child={1} toggleNav={this.toggleNav} formMode="Add" clientLoad={this.state.clientLoad} clientList={this.state.clientList} client={this.state.client} />) : (<Navigate to="/login" />)
                        } />
                        <Route path="/organisasi-merek/edit/:idMerek" element={
                          this.state.signIn ? (<OrganizationBrandForm updatePage={this.updatePage} nav={this.state.nav[5]} child={1} toggleNav={this.toggleNav} formMode="Edit" clientLoad={this.state.clientLoad} clientList={this.state.clientList} />) : (<Navigate to="/login" />)
                        } />
                      </>
                    ) : ''}
                    {/* Organisasi Toko */}
                    {this.state.nav[5].child[2].show ? (
                      <>
                        <Route path="/organisasi-outlet" element={
                          this.state.signIn ? (<OrganizationOutlet updatePage={this.updatePage} nav={this.state.nav[5]} child={2} toggleNav={this.toggleNav} general={this.state.general} brandLoad={this.state.brandLoad} brandList={this.state.brandList} client={this.state.client} brand={this.state.brand} />) : (<Navigate to="/login" />)
                        } />
                        <Route path="/organisasi-outlet/tambah" element={
                          this.state.signIn ? (<OrganizationOutletForm updatePage={this.updatePage} nav={this.state.nav[5]} child={2} toggleNav={this.toggleNav} formMode="Add" brandLoad={this.state.brandLoad} brandList={this.state.brandList} client={this.state.client} brand={this.state.brand} />) : (<Navigate to="/login" />)
                        } />
                        <Route path="/organisasi-outlet/edit/:idOutlet" element={
                          this.state.signIn ? (<OrganizationOutletForm updatePage={this.updatePage} nav={this.state.nav[5]} child={2} toggleNav={this.toggleNav} formMode="Edit" brandLoad={this.state.brandLoad} brandList={this.state.brandList} />) : (<Navigate to="/login" />)
                        } />
                      </>
                    ) : ''}
                    {/* Organisasi Group */}
                    {this.state.nav[5].child[3].show ? (
                      <>
                        <Route path="/organisasi-group" element={
                          this.state.signIn ? (<OrganizationGroup updatePage={this.updatePage} nav={this.state.nav[5]} child={3} toggleNav={this.toggleNav} general={this.state.general} />) : (<Navigate to="/login" />)
                        } />
                        <Route path="/organisasi-group/tambah" element={
                          this.state.signIn ? (<OrganizationGroupForm updatePage={this.updatePage} nav={this.state.nav[5]} child={3} toggleNav={this.toggleNav} formMode="Add" />) : (<Navigate to="/login" />)
                        } />
                        <Route path="/organisasi-group/edit/:idGroup" element={
                          this.state.signIn ? (<OrganizationGroupForm updatePage={this.updatePage} nav={this.state.nav[5]} child={3} toggleNav={this.toggleNav} formMode="Edit" />) : (<Navigate to="/login" />)
                        } />
                      </>
                    ) : ''}
                    {/* Organisasi Label */}
                    {this.state.nav[5].child[4].show ? (
                      <>
                        <Route path="/organisasi-label" element={
                          this.state.signIn ? (<OrganizationLabel updatePage={this.updatePage} nav={this.state.nav[5]} child={4} toggleNav={this.toggleNav} />) : (<Navigate to="/login" />)
                        } />
                        <Route path="/organisasi-label/tambah" element={
                          this.state.signIn ? (<OrganizationLabelForm updatePage={this.updatePage} nav={this.state.nav[5]} child={4} toggleNav={this.toggleNav} formMode="Add" />) : (<Navigate to="/login" />)
                        } />
                        <Route path="/organisasi-label/edit/:idLabel" element={
                          this.state.signIn ? (<OrganizationLabelForm updatePage={this.updatePage} nav={this.state.nav[5]} child={4} toggleNav={this.toggleNav} formMode="Edit" />) : (<Navigate to="/login" />)
                        } />
                      </>
                    ) : ''}
                    {/* Organisasi Rekening */}
                    {this.state.nav[5].child[5].show ? (
                      <>
                        <Route path="/organisasi-rekening" element={
                          this.state.signIn ? (<OrganizationBank updatePage={this.updatePage} nav={this.state.nav[5]} child={5} toggleNav={this.toggleNav} general={this.state.general} brandLoad={this.state.brandLoad} brandList={this.state.brandList} client={this.state.client} brand={this.state.brand} />) : (<Navigate to="/login" />)
                        } />
                        <Route path="/organisasi-rekening/tambah" element={
                          this.state.signIn ? (<OrganizationBankForm updatePage={this.updatePage} nav={this.state.nav[5]} child={5} toggleNav={this.toggleNav} formMode="Add" brandLoad={this.state.brandLoad} brandList={this.state.brandList} client={this.state.client} brand={this.state.brand} />) : (<Navigate to="/login" />)
                        } />
                        <Route path="/organisasi-rekening/edit/:idAccount" element={
                          this.state.signIn ? (<OrganizationBankForm updatePage={this.updatePage} nav={this.state.nav[5]} child={5} toggleNav={this.toggleNav} formMode="Edit" brandLoad={this.state.brandLoad} brandList={this.state.brandList} />) : (<Navigate to="/login" />)
                        } />
                      </>
                    ) : ''}
                    {/* Pengguna Anggota */}
                    {this.state.nav[6].child[0].show ? (
                      <>
                        <Route path="/pengguna-anggota" element={
                          this.state.signIn ? (<UserMember updatePage={this.updatePage} nav={this.state.nav[6]} child={0} toggleNav={this.toggleNav} dataUser={this.state.dataUser} />) : (<Navigate to="/login" />)
                        } />
                        {/* <Route path="/pengguna-anggota/tambah" element={
                          this.state.signIn ? (<UserMemberForm updatePage={this.updatePage} nav={this.state.nav[6]} child={0} toggleNav={this.toggleNav} formMode="Add" />) : (<Navigate to="/login" />)
                        } /> */}
                        <Route path="/pengguna-anggota/edit/:idAnggota" element={
                          this.state.signIn ? (<UserMemberForm updatePage={this.updatePage} nav={this.state.nav[6]} child={0} toggleNav={this.toggleNav} formMode="Edit" />) : (<Navigate to="/login" />)
                        } />
                      </>
                    ) : ''}
                    {/* Pengguna Admin */}
                    {this.state.nav[6].child[1].show ? (
                      <>
                        <Route path="/pengguna-admin" element={
                          this.state.signIn ? (<UserAdmin updatePage={this.updatePage} nav={this.state.nav[6]} child={1} toggleNav={this.toggleNav} dataUser={this.state.dataUser} />) : (<Navigate to="/login" />)
                        } />
                        <Route path="/pengguna-admin/tambah" element={
                          this.state.signIn ? (<UserAdminForm updatePage={this.updatePage} nav={this.state.nav[6]} child={1} toggleNav={this.toggleNav} formMode="Add" />) : (<Navigate to="/login" />)
                        } />
                        <Route path="/pengguna-admin/edit/:idAdmin" element={
                          this.state.signIn ? (<UserAdminForm updatePage={this.updatePage} nav={this.state.nav[6]} child={1} toggleNav={this.toggleNav} formMode="Edit" />) : (<Navigate to="/login" />)
                        } />
                      </>
                    ) : ''}
                    {/* Konfigurasi LINK */}
                    {this.state.nav[7].child[0].show ? (
                      <Route path="/konfigurasi-bagikan-tautan" element={
                        this.state.signIn ? (<ConfigLink updatePage={this.updatePage} nav={this.state.nav[7]} child={0} toggleNav={this.toggleNav} general={this.state.general} />) : (<Navigate to="/login" />)
                      } />
                    ) : ''}
                    {/* Konfigurasi FAQ */}
                    {this.state.nav[7].child[1].show ? (
                      <>
                        <Route path="/konfigurasi-faq" element={
                          this.state.signIn ? (<ConfigFaq updatePage={this.updatePage} nav={this.state.nav[7]} child={1} toggleNav={this.toggleNav} general={this.state.general} />) : (<Navigate to="/login" />)
                        } />
                        <Route path="/konfigurasi-faq/tambah" element={
                          this.state.signIn ? (<ConfigFaqForm updatePage={this.updatePage} nav={this.state.nav[7]} child={1} toggleNav={this.toggleNav} formMode="Add" />) : (<Navigate to="/login" />)
                        } />
                        <Route path="/konfigurasi-faq/edit/:idFaq" element={
                          this.state.signIn ? (<ConfigFaqForm updatePage={this.updatePage} nav={this.state.nav[7]} child={1} toggleNav={this.toggleNav} formMode="Edit" />) : (<Navigate to="/login" />)
                        } />
                      </>
                    ) : ''}
                    {/* Konfigurasi Term */}
                    {this.state.nav[7].child[2].show ? (
                      <Route path="/konfigurasi-ketentuan-penggunaan" element={
                        this.state.signIn ? (<ConfigTerm updatePage={this.updatePage} nav={this.state.nav[7]} child={2} toggleNav={this.toggleNav} />) : (<Navigate to="/login" />)
                      } />
                    ) : ''}
                    {/* Konfigurasi Privacy */}
                    {this.state.nav[7].child[3].show ? (
                      <Route path="/konfigurasi-kebijakan-privasi" element={
                        this.state.signIn ? (<ConfigPrivacy updatePage={this.updatePage} nav={this.state.nav[7]} child={3} toggleNav={this.toggleNav} />) : (<Navigate to="/login" />)
                      } />
                    ) : ''}
                    {/* Konfigurasi Deletion User */}
                    {this.state.nav[7].child[4].show ? (
                      <Route path="/konfigurasi-penghapusan-data-pengguna" element={
                        this.state.signIn ? (<ConfigDeletion updatePage={this.updatePage} nav={this.state.nav[7]} child={4} toggleNav={this.toggleNav} />) : (<Navigate to="/login" />)
                      } />
                    ) : ''}
                    {/* Konfigurasi Hak Akses */}
                    {this.state.nav[7].child[5].show ? (
                      <Route path="/konfigurasi-hak-akses" element={
                        this.state.signIn ? (<ConfigAccess updatePage={this.updatePage} nav={this.state.nav[7]} child={5} toggleNav={this.toggleNav} navList={this.state.nav} />) : (<Navigate to="/login" />)
                      } />
                    ) : ''}
                    {/* Konfigurasi Bank */}
                    {this.state.nav[7].child[6].show ? (
                      <>
                        <Route path="/konfigurasi-bank" element={
                          this.state.signIn ? (<ConfigBank updatePage={this.updatePage} nav={this.state.nav[7]} child={6} toggleNav={this.toggleNav} general={this.state.general} />) : (<Navigate to="/login" />)
                        } />
                        <Route path="/konfigurasi-bank/tambah" element={
                          this.state.signIn ? (<ConfigBankForm updatePage={this.updatePage} nav={this.state.nav[7]} child={6} toggleNav={this.toggleNav} formMode="Add" />) : (<Navigate to="/login" />)
                        } />
                        <Route path="/konfigurasi-bank/edit/:idBank" element={
                          this.state.signIn ? (<ConfigBankForm updatePage={this.updatePage} nav={this.state.nav[7]} child={6} toggleNav={this.toggleNav} formMode="Edit" />) : (<Navigate to="/login" />)
                        } />
                      </>
                    ) : ''}
                    {/* Konfigurasi Pengaturan */}
                    {this.state.nav[7].child[7].show ? (
                      <Route path="/konfigurasi-pengaturan" element={
                        this.state.signIn ? (<ConfigSetting updatePage={this.updatePage} nav={this.state.nav[7]} child={7} toggleNav={this.toggleNav} general={this.state.general} />) : (<Navigate to="/login" />)
                      } />
                    ) : ''}
                    
                    {/* Global */}
                    <Route path="/" element={
                      <Navigate to={this.state.signIn ? '/dashboard' : '/login'} />
                    } />
                    <Route path="*" element={
                      this.state.signIn ? (<Navigate to="/dasbor-utama" />) : (<Navigate to="/login" />)
                    } />
                  </Routes>
                </Suspense>
              )}
            </li>
          </ul>
        )}
      </>
    );
  }
}

export default App;
